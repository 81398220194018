import { AUTH_DETAIL } from '../constants/auth';
import * as storage from '../../utils/storage';
import {
  VENDOR,
  CUSTOMER,
  VALID_VIDEO_TYPES,
  VALID_IMAGE_TYPES,
} from '../constants/constant';
/**
 * Get logged in user details
 */
export const getAuthDetails = () => {
  if (isLoggedIn()) {
    return JSON.parse(storage.get(AUTH_DETAIL));
  }
};

/**
 * Is user logged in.
 */
export const isLoggedIn = () => {
  const auth = storage.get(AUTH_DETAIL);
  if (auth) return true;

  return false;
};

/**
 * Is user role vendor
 *
 * @returns
 */
export const isVendor = () => {
  if (!isLoggedIn()) return false;

  const auth = getAuthDetails();
  if (auth.role == VENDOR) return true;

  return false;
};

/**
 * Is user role customer
 *
 * @returns
 */
export const isCustomer = () => {
  if (!isLoggedIn()) return false;

  const auth = getAuthDetails();
  if (auth.role == CUSTOMER) return true;

  return false;
};

/**
 * Get logged in user details
 */
export const setAuthDetails = (authDetails) => {
  return storage.set(AUTH_DETAIL, JSON.stringify(authDetails));
};

/**
 * Get accepted file type validated
 */
export const validateFileType = (file) => {
  if (
    VALID_VIDEO_TYPES.includes(file.type) ||
    VALID_IMAGE_TYPES.includes(file.type) ||
    file.name.toLowerCase().endsWith('.heic')
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * Get accepted image file type validated
 */
export const validateImageFileType = (file) => {
  if (
    VALID_IMAGE_TYPES.includes(file.type) ||
    file.name.toLowerCase().endsWith('.heic')
  ) {
    return true;
  } else {
    return false;
  }
};
