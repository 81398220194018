import { Grid, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MuiAlert, { AlertProps } from '@mui/lab/Alert';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  center: {
    textAlign: 'center',
  },
}));

const Notification = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.center}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={true}
        autoHideDuration={6000}
      >
        <Alert severity="info">New Posts</Alert>
      </Snackbar>
    </Grid>
  );
};

export default Notification;
