import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
  Chip,
  FormControlLabel,
  FormHelperText,
  Radio,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from '@mui/material';
import { FormControl } from '@mui/base';
import RadioGroup from '@mui/material/RadioGroup';
import { SEARCH_INTERESTS_LIST } from '../../graphQL/queries/tag';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { CREATE_PROFILE } from '../../graphQL/mutations/user';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const ProfileModal = ({ isProfileComplete }: any) => {
  const [activeStep, setActiveStep] = useState(0);
  const [gender, setGender] = useState<string>('');
  const [error, setError] = useState<string>('');

  const [age, setAge] = useState<number>();
  const [open, setOpen] = useState<boolean>(!isProfileComplete);

  const [interestIds, setInterestIds] = useState<number[]>([]);

  const { data: interests } = useQuery(SEARCH_INTERESTS_LIST, {
    fetchPolicy: 'no-cache',
  });
  const [createProfile] = useMutation(CREATE_PROFILE);

  const onClickInterest = (interestId: number) => {
    if (interestIds.includes(interestId)) {
      interestIds.splice(interestIds.indexOf(interestId), 1);
      setInterestIds([...interestIds]);
    } else {
      setInterestIds([...interestIds, interestId]);
    }
  };

  const onChangeAge = (e) => {
    setAge(e.target.value);
  };

  const onChangeGender = (event) => {
    setGender(event.target.value);
  };

  const onSaveProfile = (e) => {

    if(interestIds.length < 2) {
      setError('Please select atleast 3 interest.');
      return;
    }
    
    e.preventDefault();
    createProfile({
      variables: {
        data: {
          age: Number(age),
          interestIds,
          gender,
        },
      },
    }).then((res: any) => {
      setOpen(false);
    });
  };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stepper activeStep={activeStep} alternativeLabel>
            <Step>
              <StepLabel></StepLabel>
            </Step>
            <Step>
              <StepLabel></StepLabel>
            </Step>
            <Step>
              <StepLabel></StepLabel>
            </Step>
          </Stepper>
          {activeStep === 0 && (
            <div>
              <Typography style={{ margin: 24 }} variant="h1">
                Whats your gender?
              </Typography>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={gender}
                  onChange={onChangeGender}
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
              <Button
                fullWidth
                style={{ margin: 4, marginTop: 24 }}
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveStep(1);
                }}
              >
                Next
              </Button>
            </div>
          )}
          {activeStep === 1 && (
            <div>
              <Typography style={{ margin: 24 }} variant="h1">
                Whats your age?
              </Typography>
              <TextField
                type="number"
                id="standard-basic"
                label="Age"
                fullWidth
                variant="standard"
                onChange={onChangeAge}
              />
              <Button
                fullWidth
                style={{ margin: 4, marginTop: 24 }}
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveStep(2);
                }}
              >
                Next
              </Button>
            </div>
          )}
          {activeStep === 2 && (
            <div>
              <Typography style={{ margin: 24 }} variant="h2">
                Whats are you interested in?
              </Typography>
              <div>
                {interests?.interests.length > 0 &&
                  interests?.interests.map((interest) => {
                    return (
                      <Chip
                        style={{ margin: 4 }}
                        color={
                          interestIds.includes(interest.id)
                            ? 'success'
                            : 'default'
                        }
                        key={interest.id}
                        label={interest.name}
                        onClick={() => {
                          onClickInterest(interest.id);
                        }}
                      />
                    );
                  })}

                {error && (<FormHelperText error>{error}</FormHelperText>)}
                
              </div>

              <Button
                fullWidth
                style={{ margin: 4, marginTop: 24 }}
                variant="contained"
                onClick={onSaveProfile}
              >
                Save
              </Button>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};
