import { useSubscription } from '@apollo/client';
import { Container, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Brightness1 } from '@mui/icons-material';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import { IS_CUSTOMER } from '../frontend/core/constants/middleware';
import useMiddlewares from '../frontend/core/useMiddleware';
import { getAuthDetails } from '../frontend/core/services/auth';
import MetaDecorator from '../frontend/core/meta/MetaDecorator';
import { POST_CREATED_SUBSCRIPTION } from '../frontend/graphQL/subscriptions/posts';
import Notification from '../frontend/components/shared/PostNotification';
import ForYou from '../frontend/components/feeds/ForYou';
import { ProfileModal } from '../frontend/components/home/ProfileModal';
const TopNav = dynamic(() => import('../frontend/components/navigate/TopNav'));
const BottomNav = dynamic(
  () => import('../frontend/components/navigate/BottomNav'),
);

import Explore from '../frontend/components/feeds/Explore';

//const Explore = dynamic(() => import('../frontend/components/feeds/Explore'));
const Following = dynamic(
  () => import('../frontend/components/feeds/Following'),
);

const useStyles = makeStyles(() => ({
  dot: {
    color: '#F26B1D',
    fontSize: '0.60rem',
  },
}));

const Home = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { loading, data } = useSubscription(POST_CREATED_SUBSCRIPTION);
  const [showNotification, setShowNotification] = useState(true);

  const classes = useStyles();
  const { isLoggedIn, isVendor, loadingUser, user } = useMiddlewares([
    IS_CUSTOMER,
  ]);

  const RenderNotification = () => {
    if (!loading) {
      const authDetails = getAuthDetails();
      if (data?.postCreated?.followerIds) {
        if (
          authDetails &&
          data.postCreated.followerIds.includes(authDetails.id)
        ) {
          if (showNotification) {
            setTimeout(() => {
              setShowNotification(false);
            }, 5000);
            return <Notification />;
          }
        }
      }
    }
    return null;
  };

  const ShowDot = () => {
    if (!loading) {
      const authDetails = getAuthDetails();
      if (data?.postCreated?.followerIds) {
        if (
          authDetails &&
          data.postCreated.followerIds.includes(authDetails.id)
        ) {
          return (
            <span style={{ paddingLeft: '6px' }}>
              <Brightness1 className={classes.dot} />{' '}
            </span>
          );
        }
      }
    }
    return null;
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div className="container">
      <MetaDecorator
        title="HoverIT"
        description="Buy and sell products on this social media app"
        imageUrl="http://app.hoverit.com/logo.png"
      />
      <Container maxWidth="sm">
        {/* Menu Bar */}
        <TopNav topNavType="customer" isLoggedIn={isLoggedIn} />
        {isLoggedIn ? (
          <div style={{ paddingBottom: '60px' }}>
            <Tabs
              value={selectedTab}
              variant="fullWidth"
              textColor="inherit"
              indicatorColor="secondary"
              onChange={handleTabChange}
            >
              <Tab
                id="following"
                label={
                  <>
                    Following <ShowDot />
                  </>
                }
              />
              <Tab id="for-you" label="For You" />
            </Tabs>
            <div>
              {selectedTab === 0 && (
                <Following isLoggedIn={isLoggedIn} isVendor={isVendor} />
              )}
              {selectedTab === 1 && (
                <ForYou isLoggedIn={isLoggedIn} isVendor={isVendor} />
              )}
            </div>
            <RenderNotification />
            {isLoggedIn && !loadingUser && !user?.me?.is_profile_complete && (
              <ProfileModal isProfileComplete={user?.me?.is_profile_complete} />
            )}
          </div>
        ) : (
          <div style={{ paddingBottom: '60px' }}>
            <Explore isLoggedIn={isLoggedIn} isVendor={isVendor} />
          </div>
        )}

        {/* footer menu */}
        <BottomNav />
      </Container>
    </div>
  );
};

export default Home;
